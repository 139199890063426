import { useTheme } from "@mui/material";
import { StytchPasswordReset, useStytch, useStytchUser } from "@stytch/react";
import { Products, StyleConfig } from "@stytch/vanilla-js";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { createUser } from "settings/api/Users";
import { Toast } from "shared/components/Toast";
import styled from "styled-components";

const SetPassword = styled.div`
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const config = {
  passwordOptions: {
    loginExpirationMinutes: 30,
    loginRedirectURL: `${window.location.origin}/authenticate`,
    resetPasswordExpirationMinutes: 30,
    resetPasswordRedirectURL: `${window.location.origin}/set-password`,
  },
  products: [Products.passwords],
};

export const SetPasswordPage: React.FC = () => {
  const [passwordResetToken, setPasswordResetToken] = useState("");
  const { user } = useStytchUser();
  const { session } = useStytch();
  const theme = useTheme();

  const [openToast, setOpenToast] = useState(false);

  const styles: StyleConfig = {
    fontFamily: theme.typography.fontFamily,
    buttons: {
      primary: {
        backgroundColor: theme.palette.primary.main,
        textColor: theme.palette.text.primary,
      },
    },
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    setPasswordResetToken(token ?? "");
  }, []);

  const setUser = useCallback(async () => {
    if (user && session.getTokens()) {
      try {
        axios.defaults.headers.common = {
          ...axios.defaults.headers.common,
          Authorization: session.getTokens()?.session_jwt,
        };

        console.log(user.user_id);
        await createUser(user.user_id);
        console.log("Move?");

        window.location.replace("/thoughts");
      } catch (err) {
        console.log(err);
        setOpenToast(true);
      }
    }
  }, [user, session]);

  useEffect(() => {
    setUser();
  }, [setUser]);

  return (
    <SetPassword>
      <StytchPasswordReset
        config={config}
        passwordResetToken={passwordResetToken}
        styles={styles}
      />
      <Toast
        message="Something went wrong while Setting password. Please contact us."
        open={openToast}
      />
    </SetPassword>
  );
};
