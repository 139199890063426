import { Container, Grid, Link, Typography } from "@mui/material";
import { Fragment } from "react";

export const About: React.FC = () => {
  return (
    <Fragment>
      <Container>
        <Grid container>
          <Grid xs={2} item></Grid>
          <Grid xs={8} item>
            <Typography
              variant="h3"
              style={{ marginBottom: "12px", textAlign: "center" }}
            >
              About
            </Typography>
          </Grid>
          <Grid xs={2} item></Grid>
        </Grid>
      </Container>
      <Container style={{ marginTop: "32px" }}>
        <Grid container>
          <Grid xs={12} item>
            <Typography variant="body1">
              <p>
                <b>ThoughtStream.me</b> is a simple note taking app focused on
                getting raw thoughts onto "paper". I found myself writing notes
                as if I was speaking aloud, or in a "stream" in a notebook.
                However, I found the notebook awkward, and difficult to search
                for useful thoughts.
              </p>
              <p>
                So I decided to build this app as a place where I can "stream"
                my thoughts during meetings, while I'm working on something
                difficult, or as a way to process something emotional. I hope
                you find it as useful as I have, and it helps bring some level
                of order to personal chaos. If you've got any suggestions,
                thoughts, or ideas, let me know via{" "}
                <Link
                  color="inherit"
                  target="_blank"
                  rel="noreferrer"
                  href="https://mstdn.social/@hazz223"
                >
                  Mastodon
                </Link>
                {" / "}
                <Link
                  color="inherit"
                  target="_blank"
                  rel="noreferrer"
                  href="https://bsky.app/profile/harrywinser.com"
                >
                  Bluesky!
                </Link>
              </p>
              <p>Harry</p>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container style={{ marginTop: "32px" }}>
        <Grid container>
          <Grid xs={12} item>
            <Typography variant="h4">Privacy</Typography>
            <Typography variant="body1">
              <p>
                All notes are encrypted against a key, protecting data in the
                horrid event that bad actors get access to the database.
                However, titles <strong>aren't</strong>, so I'd recommend not
                putting anything personal in a title field. I'll do my best to
                ensure that your thoughts are secure. If you'd like your data
                removed, changed, or whatever, please contact me via Mastodon,
                or via email
                <Link
                  color="inherit"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.harrywinser.com"
                >
                  (which you can find on my website)
                </Link>
                .
              </p>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container style={{ marginTop: "32px" }}>
        <Grid container>
          <Grid xs={12} item>
            <Typography variant="h4">Technology</Typography>
            <Typography variant="body1">
              <p>For those interested, this site is written using: </p>
              <ul>
                <li>Typescript</li>
                <li>Vite</li>
                <li>React</li>
              </ul>
              <p>The Backend is written using:</p>
              <ul>
                <li>AWS Lambda</li>
                <li>
                  <a href="https://xata.io/">Xata</a> for data storage
                </li>
              </ul>
              <p>Some of the 3rd party libraries I&#39;ve used are:</p>
              <ul>
                <li>
                  <a href="https://www.typeitjs.com/">TypeIt</a>
                </li>
                <li>
                  <a href="https://mui.com/">MaterialUi</a>
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};
