import { Grid2 as Grid } from "@mui/material";
import { ThoughtAction } from "./components/ThoughtAction";
import { ThoughtList } from "./components/ThoughtList";
import { ThoughtHeader } from "./components/ThoughtHeader";
import { Fragment } from "react/jsx-runtime";
import { Loading } from "shared/components/Loading";
import { useThoughts } from "./hooks/useThoughts";
import { ThoughtTags } from "./components/ThoughtTags";

export const Page: React.FC = () => {
  const { isLoading } = useThoughts();

  return (
    <Fragment>
      {isLoading ? (
        <Loading>Loading thought...</Loading>
      ) : (
        <Grid container style={{ minHeight: "60vh" }}>
          <Fragment>
            <Grid size={12} style={{ overflow: "scroll" }}>
              <ThoughtHeader />
            </Grid>

            <Grid size={12}>
              <ThoughtList />
            </Grid>

            <Grid size={12}>
              <ThoughtAction />
            </Grid>

            <Grid size={12}>
              <ThoughtTags />
            </Grid>
          </Fragment>
        </Grid>
      )}
    </Fragment>
  );
};
