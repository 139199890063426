import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import DOMPurify from "dompurify";
import { convertToHtml } from "shared/utils";
import { Thought } from "thought/types";
import styled from "styled-components";
import { Theme, useTheme } from "@mui/material/styles";

interface Props {
  thought: Thought;
}

const ThoughtDiv = styled.div<{ theme: Theme }>`
  a {
    text-decoration: underline;
    color: inherit;
  }
  strong {
    font-weight: 1000;
  }
  code {
    background: ${(p) => p.theme.palette.primary.main}};
  }
`;

const dateOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

const formatThoughtTime = (given: Date): string => {
  return given.toLocaleTimeString("en-GB", dateOptions);
};

export const ThoughtListItem: React.FC<Props> = ({ thought }) => {
  const theme = useTheme();

  return (
    <TimelineItem>
      <TimelineOppositeContent color="text.secondary">
        {thought.createdAt && formatThoughtTime(thought.createdAt)}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot color="secondary" />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <ThoughtDiv
          theme={theme}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(convertToHtml(thought.body)),
          }}
        />
      </TimelineContent>
    </TimelineItem>
  );
};
