import { Grid2 as Grid, Typography, useTheme } from "@mui/material";
import { Fragment } from "react";
import desktopExample from "../assests/desktop-example.png";

export const Home: React.FC = () => {
  const theme = useTheme();

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid size={{ xs: 12, sm: 8 }}>
          <Typography variant="h3">Thought-Stream</Typography>
          <Typography variant="body1">
            <p>
              <b>ThoughtStream.me</b> is a simple note taking app focused on
              getting raw thoughts onto "paper". <b>ThoughtStream.me</b> is a
              great app to help take notes during a meeting, keeping your
              thoughts organised throughout the day, or for keeping a nightly
              diary.
            </p>
            <p>
              Whatever you use it for, we hope you get something valuable out of
              it.
            </p>
          </Typography>
        </Grid>
        <Grid
          size={{ xs: 12, sm: 4 }}
          style={{ border: "solid", borderColor: theme.palette.primary.main }}
        >
          <img
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              overflow: "hidden",
            }}
            src={desktopExample}
            alt="Example of the app on a macOS desktop"
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};
