import { Grid2 as Grid, useTheme } from "@mui/material";
import { StytchLogin, useStytch, useStytchUser } from "@stytch/react";
import { Products, StyleConfig } from "@stytch/vanilla-js";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Fragment } from "react/jsx-runtime";
import { createUser } from "settings/api/Users";
import { Toast } from "shared/components/Toast";

export const LoginPage = () => {
  const REDIRECT_URL = `${window.location.origin}/authenticate`;
  const RESET_PASSWORD_URL = `${window.location.origin}/set-password`;
  const { user } = useStytchUser();
  const { session } = useStytch();
  const theme = useTheme();
  const [openToast, setOpenToast] = useState(false);

  const styles: StyleConfig = {
    fontFamily: theme.typography.fontFamily,
    container: {
      width: "100%",
    },
    buttons: {
      primary: {
        backgroundColor: theme.palette.primary.main,
        textColor: theme.palette.text.primary,
      },
    },
  };
  const config = {
    products: [Products.emailMagicLinks, Products.passwords],
    emailMagicLinksOptions: {
      loginRedirectURL: REDIRECT_URL,
      loginExpirationMinutes: 60,
      signupRedirectURL: REDIRECT_URL,
      signupExpirationMinutes: 60,
    },
    passwordOptions: {
      loginExpirationMinutes: 30,
      loginRedirectURL: REDIRECT_URL,
      resetPasswordExpirationMinutes: 30,
      resetPasswordRedirectURL: RESET_PASSWORD_URL,
    },
    sessionOptions: {
      sessionDurationMinutes: 60 * 18, // 18 hours login
    },
  };

  const setUser = useCallback(async () => {
    if (user && session.getTokens()) {
      try {
        axios.defaults.headers.common = {
          ...axios.defaults.headers.common,
          Authorization: session.getTokens()?.session_jwt,
        };

        console.log(user.user_id);
        await createUser(user.user_id);
        console.log("Move?");

        window.location.replace("/thoughts");
      } catch (err) {
        console.log(err);
        setOpenToast(true);
      }
    }
  }, [user, session]);

  useEffect(() => {
    setUser();
  }, [setUser]);

  return (
    <Fragment>
      <Grid id="page" container>
        <Grid size={12} style={{ maxWidth: "500px", margin: "0 auto" }}>
          <StytchLogin config={config} styles={styles} />
        </Grid>
      </Grid>
      <Toast
        message="Something went wrong while logging in. Please contact us"
        open={openToast}
      />
    </Fragment>
  );
};
