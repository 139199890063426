import { useEffect, useState } from "react";
import { ThoughtStreamItem } from "../../types";
import { getThoughtStreams } from "../api";
import { isAxiosError } from "axios";

export const useThoughtStreams = () => {
  const [thoughtStreams, setThoughtStreams] = useState<ThoughtStreamItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      try {
        const thoughtSteams = await getThoughtStreams();
        setThoughtStreams(thoughtSteams);
        setIsLoading(false);
      } catch (err) {
        if (isAxiosError(err)) {
          if (err.status === 401) {
            window.location.replace("/not-authenticated");
          }
        } else {
          console.log(err);
        }
      }
    })();
  }, [setThoughtStreams]);

  return { thoughtStreams, isLoading };
};
