import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useStytch, useStytchUser } from "@stytch/react";
import { Draw } from "./Draw";
import { Fragment, useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import { Link, NavLink } from "react-router-dom";

export const NavigationBar: React.FC = () => {
  const user = useStytchUser();
  const stytchClient = useStytch();
  const [open, setOpen] = useState(false);
  const onLargeScreen = useMediaQuery("(min-width:600px)");

  const onClose = () => {
    setOpen(false);
  };

  const onLogout = async () => {
    await stytchClient.session.revoke();
    window.location.replace("/");
  };

  const headerLink = user.user ? "/thoughts" : "/";

  const theme = useTheme();

  return (
    <Box style={{ padding: "0px" }} sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          {user.user && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => setOpen(true)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography
            variant="h6"
            component="div"
            color="primary"
            sx={{ flexGrow: 6, textAlign: "left" }}
          >
            <Link
              to={headerLink}
              style={{
                textDecoration: "none",
                color: theme.palette.text.primary,
              }}
            >
              ThoughtStream.me
            </Link>
          </Typography>
          {!user.user && (
            <NavLink
              to={"/login"}
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <Typography variant="body1">
                {onLargeScreen && "Login / Signup"}
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  href="/login"
                >
                  <LoginIcon />
                </IconButton>
              </Typography>
            </NavLink>
          )}
          {user.user && (
            <Fragment>
              <a
                href="#"
                onClick={onLogout}
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <Typography variant="body1">
                  <IconButton size="large" edge="start" color="inherit">
                    <LogoutIcon />
                  </IconButton>
                  {onLargeScreen && "Logout"}
                </Typography>
              </a>
            </Fragment>
          )}
        </Toolbar>
      </AppBar>
      <Draw isOpen={open} onClose={onClose} />
    </Box>
  );
};
