import { Fragment } from "react/jsx-runtime";
import { ThoughtStreamCard } from "./ThoughtStreamCard";
import { useThoughtStreams } from "./hooks/useThoughtStream";
import { Loading } from "shared/components/Loading";
import { Button, Container, Grid2, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const ThoughtsPage: React.FC = () => {
  const { thoughtStreams, isLoading } = useThoughtStreams();

  return (
    <Fragment>
      <Container style={{ minHeight: "60vh" }}>
        {!isLoading && (
          <Typography variant="h2" align="center">
            Previous thoughts
          </Typography>
        )}
        {isLoading ? (
          <div>
            <Loading>Loading thoughts...</Loading>
          </div>
        ) : thoughtStreams.length > 0 ? (
          thoughtStreams.map((item) => {
            return <ThoughtStreamCard key={item.id} item={item} />;
          })
        ) : (
          <Fragment>
            <Grid2 marginTop={10} textAlign={"center"}>
              <Typography>
                No previous thoughts recorded. Why don't you create a new one?
              </Typography>
            </Grid2>
            <Grid2 marginTop={10} textAlign={"center"}>
              <Link to={"/thoughts/new"}>
                <Button variant="contained">
                  <Typography>New thought</Typography>
                </Button>
              </Link>
            </Grid2>
          </Fragment>
        )}
      </Container>
    </Fragment>
  );
};
