import {
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Container,
  Grid2 as Grid,
  Typography,
} from "@mui/material";
import { ThoughtStreamItem } from "../types";
import { formatDate } from "../shared/utils/DateFormatter";
import { Link } from "react-router-dom";
import { orderTags } from "shared/utils";

interface Props {
  item: ThoughtStreamItem;
}

export const ThoughtStreamCard: React.FC<Props> = ({ item }) => {
  return (
    <Container style={{ marginTop: "32px", maxWidth: " 800px" }}>
      <Grid container>
        <Grid size={12}>
          <Link
            to={`/thoughts/${item.id}`}
            style={{ color: "black", textDecoration: "none" }}
          >
            <Card variant="outlined">
              <CardActionArea>
                <CardContent>
                  <Typography>{formatDate(item.createdAt)}</Typography>
                  <Typography variant="h5" component="div">
                    {item.title}
                  </Typography>
                  {item.tags &&
                    orderTags(item.tags).map((tag) => {
                      return (
                        <Chip
                          style={{ marginTop: "8px", marginRight: "8px" }}
                          key={tag}
                          label={tag}
                        />
                      );
                    })}
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
};
