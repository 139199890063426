import {
  Grid2 as Grid,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import styled from "styled-components";
import mastodonLogo from "../assests/mastodon-logo-purple.svg"; // Importing an image
import blueskyLogo from "../assests/bluesky-logo.svg"; // Importing an image
import { Share } from "@mui/icons-material";
import { Fragment } from "react/jsx-runtime";

const FooterWrapper = styled.div`
  width: 100%;
  margin-top: 20vh;
  height: 50px;
  color: white;
  bottom: 50px;
`;

const ImageContainer = styled.div``;

export const Footer: React.FC = () => {
  const theme = useTheme();
  const onLargeScreen = useMediaQuery("(min-width:600px)");
  return (
    <FooterWrapper>
      <Grid
        container
        spacing={1}
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
          padding: "36px",
        }}
      >
        <Grid
          size={6}
          style={{ padding: "8px", display: "flex", justifyContent: "start" }}
        >
          <Typography color={theme.palette.common.black}> </Typography>
          <Typography color={theme.palette.common.black}>
            <Link color="inherit" href="/about">
              About
            </Link>

            {onLargeScreen && (
              <Fragment>
                {" | "} Created by{" "}
                <Link
                  href="https://www.harrywinser.com"
                  color="inherit"
                  target="_blank"
                  rel="noreferrer"
                >
                  Harry Winser
                </Link>
              </Fragment>
            )}
          </Typography>
          <ImageContainer>
            <Link
              href="https://mstdn.social/@hazz223"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={mastodonLogo}
                alt="Mastodon Logo"
                style={{
                  width: "20px",
                  height: "20px",
                  marginLeft: "16px",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              />
            </Link>
          </ImageContainer>
          <ImageContainer>
            <Link
              href="https://bsky.app/profile/harrywinser.com"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={blueskyLogo}
                alt="Bluesky Logo"
                style={{
                  width: "20px",
                  height: "20px",
                  marginLeft: "16px",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              />
            </Link>
          </ImageContainer>
        </Grid>

        <Grid size={6} style={{ display: "flex", justifyContent: "end" }}>
          <div style={{ alignItems: "center" }}>
            <Typography color={theme.palette.common.black}>
              <IconButton>
                <a
                  href="https://shareopenly.org/share/?url=thoughtstream.me"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Share color="action" />
                </a>
              </IconButton>
            </Typography>
          </div>
        </Grid>
      </Grid>
    </FooterWrapper>
  );
};
