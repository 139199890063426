import axios from "axios";
import { ThoughtStreamItem } from "../../types";

export const getThoughtStreams = async (): Promise<ThoughtStreamItem[]> => {
  const result = await axios.get<ThoughtStreamItem[]>("/thoughts");

  return result.data.map((data) => {
    return {
      ...data,
      createdAt: new Date(data.createdAt),
    };
  });
};
