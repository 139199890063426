import { useThoughts } from "../hooks/useThoughts";
import { Timeline, timelineOppositeContentClasses } from "@mui/lab";
import { Grid2 as Grid } from "@mui/material";
import { Thought } from "thought/types";
import { ThoughtListItem } from "./ThoughtListItem";

export const ThoughtList: React.FC = () => {
  const { thoughts } = useThoughts();

  return (
    <Grid container style={{ marginTop: "32px" }}>
      <Grid size={12}>
        <Timeline
          position="right"
          sx={{
            [`& .${timelineOppositeContentClasses.root}`]: {
              flex: 0,
            },
          }}
        >
          {thoughts?.map((thought: Thought) => {
            return <ThoughtListItem key={thought.id} thought={thought} />;
          })}
        </Timeline>
      </Grid>
    </Grid>
  );
};
