import { Typography } from "@mui/material";
import { useStytchUser } from "@stytch/react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const ErrorBox = styled.div`
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NotAuthenticatedPage: React.FC = () => {
  const { user } = useStytchUser();

  if (user) {
    window.location.replace("/thoughts");
  }

  return (
    <ErrorBox>
      <Typography>
        Sorry, you're not authenticated. Please&nbsp;
        <Link to="/login" style={{ color: "black" }}>
          Login.
        </Link>
      </Typography>
    </ErrorBox>
  );
};
